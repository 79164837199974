<template>
  <div class="banner">
    <el-carousel height="400px" direction="horizontal">
      <el-carousel-item v-for="(item, index) in carouselData" :key="index">
        <img :src="item.imgUrl" alt="" class="banner-img" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'banner',
  data() {
    return {
      carouselData: [
        {
          id: 0,
          url: '',
          imgUrl: require('../assets/img/home/banner1.jpg'),
        }, {
          id: 1,
          url: '',
          imgUrl: require('../assets/img/home/banner2.jpg'),
        }, {
          id: 3,
          url: '',
          imgUrl: require('../assets/img/home/banner3.jpg'),
        }
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  height: 400px;

  .banner-img {
    display: block;
    max-width: 100%;
    height: 400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    display: none;
  }
}
</style>
