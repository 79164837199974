<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/generalSituation/banner.jpg" alt="" srcset="">
    </div>
    <div class="centent">
      <div class="about_us edition_heart">
        <div class="title">关于我们</div>
        <div class="titleEng">About us</div>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="introduces" style="position: relative;">
              <div class="introduce" v-for="(item, index) in introList" :key="index">
                {{ item }}
              </div>
              <!-- 装饰 -->
              <i class="line-dec line-dec-left"></i>
              <i class="line-dec line-dec-right"></i>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="company_inside ">
      <div class="company edition_heart">
        <div class="title">公司概况</div>
        <div class="titleEng">Company interior</div>
        <el-row :gutter="30" class="inside">
          <el-col :span="8" v-for="(item, index) in companyDate" :key="index">
            <img :src="item.imgUrl" alt="" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="qualification_honor">
      <div class="border">
        <div class="title">资质荣誉</div>
        <div class="titleEng">Qualifications and Honors</div>
        <div class="banner">
          <el-carousel height="491px" direction="horizontal" type="card" indicator-position="none">
            <el-carousel-item v-for="(item, index) in honorData" :key="index">
              <img :src="item.imgUrl" alt="" class="banner-img" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="banner2">
          <el-carousel height="491px" direction="horizontal" indicator-position="none">
            <el-carousel-item v-for="(item, index) in honorData" :key="index">
              <img :src="item.imgUrl" alt="" class="banner-img" style="width: 300px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="repair_plant_area">
      <div class="edition_heart">
        <div class="title">修复厂区</div>
        <div class="titleEng">Qualifications and Honors</div>
        <el-row :gutter="25" class="plant">
          <el-col :span="12" v-for="(item, index) in plantDate" :key="index">
            <img :src="item.imgUrl" alt="" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
export default {
  name: 'Home',
  components: { banner },
  data() {
    return {
      // 关于我们
      introList: [
        '山东文保文物修复技术有限公司位于济南市高新区，公司成立于2015年，注册资金1000万元。办公面积两千余平方米，目前职工100余人，为济南市重点文化扶持企业。',
        '山东文保文物修复技术有限公司自成立以来为全国多个文物保护修复中心、各大博物馆，提 供文物综合服务，服务内容包括：陶瓷器、金属器、以及书画古籍等文物的保护修复工作和展品 复仿制工作，同时也承担文物预防性保护项目、文物保护修复实验室建立、设备、工器具、耗材 供应等工作，与山东、河北、甘肃、江苏、广东、湖北、湖南、江西、内蒙古等各个博物馆都有 长期合作且得到众多业界好评。',
        '山东文保文物修复技术有限公司以专业为目标， 以技术为核心，坚持“同心，奋进，务实， 感恩”的企业精神，在可移动文物保护领域中扎根成长。 公司文物保护实验室建立有全面完整的 策略和体系，特聘有古籍书画保护修复、陶瓷文物保护修复、金属文物保护修复的专家团队。公司目前共设立11个部门：陶瓷保护修复部、 金属文物保护修复部、书画古籍保护修复部、展品复仿制部、商业修复部、勘探考古部、设备研发部、售后服务部、工程安装部、财务部、人力及行政部。除了常设部门外还配建有安全库房、化学品库房、影像室、科技保护室等。在场地安全和库房安全设备方面，公司还配备了先进的远红外检测器、警报控制器、无线红外对射警戒器以及大量视频监控设备，为公司提供全方位无死角的视频监控。',
        '公司在注重文物本体材质保护的同时，也注重文物的艺术价值的恢复。同时致力于文物修复材料、药剂及文物修复保护设备的研发与基础理论研究。公司在不断 提高文物保护与传承技术的业务基础上，也不断强调科研与创新。我司代理了来自英国、德国、意大利、美国等生产的专业文物保护耗材，并对这些专业材料进行了 深入的使用技法研究，使进口耗材更加符合我国文物保护的需求。目前公司正与多所省内知名大学合作进行涉及文物保护材料、文物保护药剂、文物保护环境领域的 多个科研课题。',
        '公司设有专门的售后服务部门，专为用户提供售后的指导安装、调试、维护与现场的技术培训服务，解决用户提出的问题，收集用户意见等。由专职人员受理用 户来电，保证客户及时得到技术上的支援和服务，把“24小时服务”、“超前服务”、“全过程服务”贯彻在文物保护服务、产品安装调试的全过程。客户需求会在24小 时之内作出答复或派出服务人员，进快到达现场，做到客户不满意，服务不停止。'
      ],
      companyDate: [
        { imgUrl: require('/src/assets/img/generalSituation/companyInside1.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/companyInside2.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/companyInside3.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/companyInside4.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/companyInside5.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/companyInside6.jpg') },
      ],
      plantDate: [
        { imgUrl: require('/src/assets/img/generalSituation/plant1.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/plant2.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/plant3.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/plant4.jpg') },
      ],
      honorData: [
        { imgUrl: require('/src/assets/img/generalSituation/honor1.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor2.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor3.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor4.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor5.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor6.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor7.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor8.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor9.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor10.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor11.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor12.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/honor13.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/1.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/2.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/3.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/4.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/5.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/6.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/7.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/8.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/9.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/10.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/11.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/12.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/13.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/14.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/15.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/16.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/17.jpg') },
        { imgUrl: require('/src/assets/img/generalSituation/18.jpg') },
      ],
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.outside {
  background-color: #F9F7F3;
}

.line-dec {
  display: block;
  width: 60px;
  height: 60px;
  border: 6px solid #BC7E17;
  position: absolute;
}

.line-dec-left {
  border-right: none;
  border-top: none;
  left: -1px;
  bottom: -1px;
}

.line-dec-right {
  border-left: none;
  border-bottom: none;
  right: -1px;
  top: -1px;
}

/deep/ .el-carousel__mask {
  background: none;
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;
  }

  .company_inside {
    .company {
      .title {
        padding: 10px 0 0 0;
      }
    }
  }

  .qualification_honor {
    .title {
      padding: 70px 0 0 0;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .banner2 {
      display: none;
    }
  }

  .repair_plant_area {
    .title {
      padding: 30px 0 0 0;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 70px;
  }

  .qualification_honor {
    .title {
      padding: 70px 0 0 0;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .banner2 {
      display: none;
    }
  }

  .repair_plant_area {

    .title {
      padding: 30px 0 0 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .edition_heart {
    width: 90%;
  }

  .edition_heart {
    padding-top: 40px;
  }

  .company_inside {
    .company {
      .title {
        padding: 0px 0 12px 0;
      }
    }

    ::v-deep .edition_heart {
      padding-top: 30px;
    }
  }

  .qualification_honor {
    .title {
      padding: 60px 0 0 0;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .banner2 {
      margin: 30px 0 0 0;
    }

    .banner {
      display: none;
    }
  }

  .repair_plant_area {

    .title {
      padding: 30px 0 12px 0;
    }

    ::v-deep .edition_heart {
      padding-top: 30px;
    }
  }
}

.outside {
  .centent {
    width: 100%;
    height: auto;
    background-size: 81.3% auto;
    background-position: right top;

    .about_us {
      .title {
        padding-top: 65px;
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-Heavy;
        font-weight: 800;
        text-align: left;
        color: #333;

      }

      .titleEng {
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(102, 102, 102, .4);
        line-height: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
      }

      .titleEng::before {
        content: "";
        display: block;
        width: 109px;
        height: 6px;
        background: #A40000;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .introduces {
        font-size: 16px;
        margin: 50px 20px 70px 12px;
        color: #666;
        line-height: 30px;
        position: relative;
        text-indent: 2em;
        border: 1px dashed #A2762C;
        padding: 30px;

        .introduce {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }

  .company_inside {
    width: 100%;

    .company {
      .title {
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-Heavy;
        font-weight: 800;
        text-align: left;
        color: #333;
      }

      .titleEng {
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(102, 102, 102, .4);
        line-height: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
      }

      .titleEng::before {
        content: "";
        display: block;
        width: 109px;
        height: 6px;
        background: #A40000;
        position: absolute;
        left: 0;
        bottom: 0;
      }


      .inside {
        padding-bottom: 55px;

        img {
          width: 100%;
          height: auto;
          margin-top: 30px;
          border: 5px solid rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .qualification_honor {
    width: 100%;
    height: auto;
    background: url('~@/assets/img/generalSituation/QualificationsHonors.png') no-repeat;
    background-size: cover;
    margin-bottom: 50px;

    .border {
      width: 62.5%;
      margin: 0 auto;
      margin-top: 40px;
      height: 730px;
    }

    .title {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .banner {
      height: 491px;
      position: relative;
      margin-top: 30px;

      img {
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .banner2 {
      height: 491px;
      position: relative;

      img {
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .repair_plant_area {
    width: 100%;
    height: auto;
    padding-bottom: 55px;

    .title {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    .titleEng {
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .plant {
      img {
        width: 100%;
        height: auto;
        margin-top: 30px;
        border: 5px solid rgba(255, 255, 255, 0.4);
      }
    }
  }

  // 版心
  .edition_heart {
    margin: 0px auto;
    position: relative;
  }
}
</style>
